import React from "react"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import CircularProgress from "@material-ui/core/CircularProgress"

import { COOKIE_ENDPOINT } from "../constant"

export default class Cookies extends React.Component {
  state = {
    data: null,
  }

  componentDidMount() {
    const headersData = new Headers()
    const requestOptions = {
      method: "GET",
      headers: headersData,
    }

    fetch(COOKIE_ENDPOINT, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.setState({
          data: result,
        })
      })
      .catch((error) => console.log("error", error))
  }

  render() {
    return (
      <Layout>
        <div className="relative overflow-hidden">
          <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
            <div className="md:flex p-4 flex-col">
              <div className="legal-container">
                {this.state.data ? (
                  <ReactMarkdown source={this.state.data} escapeHtml={false} />
                ) : (
                  <div className="flex justify-center">
                    <CircularProgress
                      color="inherit"
                      size={58}
                      className="self-center"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
